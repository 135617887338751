import { Component, OnInit } from '@angular/core';
import { Platform } from '@ionic/angular';
import { App, AppState } from '@capacitor/app';
import { SapService } from '@core/services/sap.service';
import { InitService } from '@core/services/init.service';
import { NavigationEnd, Router } from '@angular/router';
import { StorageService } from '@core/services/storage.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  activeSideMenu = true;
  constructor(
    private platform: Platform,
    private iService: InitService,
    private sapService: SapService,
    private storage: StorageService,
  ) {}

  ngOnInit(): void {
    this.initializeApp();
    this.isActiveApp()
    // this.iService.appInit();
  }

  activate = (ev: any) => null;

  isActiveApp() {
    App.addListener('appStateChange',
      async ({ isActive }: AppState): Promise<void> => {
        if(isActive) await this.sapService.getDataInfoToken();
      }
    );
  }

  initializeApp() {
    this.platform.ready().then(async () => {
      const user = this.storage.get('oUser');
      if (!user) this.activeSideMenu = false;
      await this.sapService.getDataInfoToken();
    });
  }
}