import { Injectable } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { StorageService } from "@core/services/storage.service";
import { Store } from '@ngrx/store';
import * as actions from '@store/actions';
import { StoreState } from "@store/store.state";

@Injectable({
  providedIn: 'root'
})
export class InitService {
  constructor(
    private store: Store<StoreState>,
    private storage: StorageService,
    private router: Router,
  ) {
  }

  appInit() {
    this.store.dispatch(actions.loadLogos());
  }
}